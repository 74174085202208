import Vue from "vue";
import App from "./App.vue";
import vuetify from "vuetify/lib";
import router from "./router/router";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./i18n";
import VueSocialSharing from "vue-social-sharing";
import siteConfig from "./configs/index";

const lang = localStorage.getItem("lang") || "ar";
let rtll = true;
if (lang == "ar") {
  rtll = true;
}
if (lang == "en") {
  rtll = false;
}
axios.defaults.baseURL = "https://dashboard.hidaarchive.com/api";
axios.defaults.headers["local"] = lang;
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueSocialSharing);
Vue.prototype.$ALBUM_ID_KEY = "album-id-key";
Vue.prototype.$CIRTIFICATE_ID_KEY = "certificate";
Vue.prototype.$PHOTO_ID_KEY = "photo-id-key";
Vue.prototype.$STUDY_ID_KEY = "study-id-key";
Vue.prototype.$VIDEO_ID_KEY = "video-id-key";
Vue.prototype.$ALBUMS_VIDEO_ID_KEY = "video-id-key";
Vue.prototype.$CLICKABLE_NAME = "clickablename";
Vue.use(vuetify);
new Vue({
  vuetify: new vuetify({
    rtl: rtll,
  }),
  router,
  i18n,
  el: "#app",
  components: { App },
  template: "<App/>",
  metaInfo: {
    title: null,
    titleTemplate: (titleChunk) => {
      return titleChunk
        ? `${titleChunk} - ${siteConfig.title}`
        : siteConfig.title;
    },
    htmlAttrs: {
      lang: "ar",
    },
    meta: [
      { name: "keyword", content: "hidaarchive" },
      { property: "og:url", vmid: "og:url", content: siteConfig.url },
      {
        name: "title",
        content: null,
        template: `${siteConfig.title}`,
        vmid: "title",
      },
      {
        property: "og:title",
        content: null,
        template: `${siteConfig.title}`,
        vmid: "og:title",
      },
      {
        name: "twitter:title",
        vmid: "twitter:title",
        template: `${siteConfig.title}`,
        content: siteConfig.title,
      },
      {
        property: "og:description",
        vmid: "og:description",
        content: siteConfig.description,
      },
      {
        property: "twitter:description",
        vmid: "twitter:description",
        content: siteConfig.description,
      },
    ],
  },
  render: (h) => h(App),
}).$mount("#app");
