<template>
  <v-app class="brown lighten-3">
    <v-main>
      <Navbar />
      <router-view></router-view>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Foot.vue";

export default {
  name: "App",
  components: { Navbar, Footer },
  data() {
    return {
      list: [],
    };
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&display=swap");
* {
  padding: 0%;
  margin: 0%;
  font-family: "Almarai", sans-serif;
}
</style>

