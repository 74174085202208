import Vue from "vue";
import VueRouter from "vue-router";
import Vuemeta from "vue-meta";
Vue.use(VueRouter);
Vue.use(Vuemeta);

export const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/certificate/:id",
    name: "certificate",
    component: () => import("../views/CertificateView.vue"),
    meta: {
      sitemap: {
        slugs: ["1", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
    },
  },
  {
    path: "/studies/GraduationProjects",
    name: "studies",
    component: () => import("../views/StudiesView.vue"),
  },
  {
    path: "/studies/InstitutePublications",
    name: "studies1",
    component: () => import("../views/studiesVue1.vue"),
  },
  {
    path: "/photogroupes",
    name: "photo",
    component: () => import("../views/Photo.vue"),
  },
  {
    path: "/album/:id/:album_name",
    name: "album",
    component: () => import("../views/AlbumView.vue"),
    meta: {
      sitemap: {
        slugs: [
          {
            id: "17",
            album_name: "سهرة مع أبي خليل القباني",
          },
          {
            id: "3",
            album_name: "شكسبيريات",
          },
          {
            id: "5",
            album_name: "امتحان في مكتبة المعهد",
          },
          {
            id: "6",
            album_name: "بيت برناردا ألبا",
          },
          {
            id: "7",
            album_name: "تدريبات على مشهد لعرضه في مهرجان أفينيون",
          },
          {
            id: "8",
            album_name: "ماسح الأحذية",
          },
          {
            id: "9",
            album_name: "تدريبات مادة الليونة",
          },
          {
            id: "10",
            album_name: "حياة فاسكو",
          },
          {
            id: "11",
            album_name: "رومولوس العظيم",
          },
          {
            id: "12",
            album_name: "شيء من موليير",
          },
          {
            id: "13",
            album_name: "مسرحية الزير سالم",
          },
          {
            id: "14",
            album_name: "غسان المالح",
          },
          {
            id: "15",
            album_name: "الليلة الثانية عشرة",
          },
          {
            id: "16",
            album_name: "مشروع تخرج الفصل الأول",
          },
          {
            id: "18",
            album_name: "طبيب رغماً عنه",
          },
          {
            id: "19",
            album_name: "يرما",
          },
          {
            id: "20",
            album_name: "يوليوس قيصر",
          },
          {
            id: "21",
            album_name: "مغامرة رأس المملوك جابر",
          },
          {
            id: "22",
            album_name: "بحر",
          },
        ],
      },
    },
  },
  {
    path: "/video",
    name: "video",
    component: () => import("../views/Video.vue"),
  },
  {
    path: "/album/:id/single_photo/:photo_id",
    name: "single_photo",
    component: () => import("../views/Single.vue"),
    meta: {
      sitemap: {
        slugs: [
          {
            id: "17",
            photo_id: "415",
          },
        ],
      },
    },
  },
  {
    path: "/study/:id",
    name: "single_study",
    component: () => import("../views/StudyView.vue"),
    meta: {
      sitemap: {
        slugs: [
          "2",
          "3",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
        ],
      },
    },
  },
  {
    path: "/certificatevideo/:cerid/:vidid",
    name: "single_cervideo",
    component: () => import("../views/CertificateVideo.vue"),
    meta: {
      sitemap: {
        slugs: [
          {
            cerid: "1",
            vidid: "7",
          },
        ],
      },
    },
  },
  {
    path: "/albumvideo/:videoid",
    name: "albumvideo",
    component: () => import("../views/SingleVideo.vue"),
    meta: {
      sitemap: {
        slugs: ["66"],
      },
    },
  },
  {
    path: "/profile/:name",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: {
      sitemap: {
        slugs: [
          "Ayman Zedan",
          "Sharif Shaker",
          "Saadallah Wannous",
          "omar abusaada",
          "Naila Al-Atrash",
          "Nabil Al-Haffar",
          "Naaman Joud",
          "Mayada Hussein",
          "Mary Elias",
          "Manuel Jiji",
          "Jawad Al-Assadi",
          "Hannan Kassab Hassan",
          "Ghassan Al-Maleh",
          "Fouad Hassan",
          "Fayez Kazak",
          "Fawaz Al-Sajer",
          "Asaad Feddah",
          "Ajaj Salim",
        ],
      },
    },
  },
  {
    path: "/search/:name",
    name: "search",
    component: () => import("../views/SearchView.vue"),
  },
];

Vue.use(Vuemeta);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  next();
});

export default router;
