<template >
  <v-footer height="520px" color="#BDBDBD" class="font-weight-black mt-15 mx-0">
    <v-row justify="center" class="pa-0 ma-0">
      <v-col cols="12" md="2">
        <div class="text-center">
          <v-btn text plain x-large to="/about">
            {{ $t("footer.about") }}</v-btn
          >
        </div>
        <v-divider> </v-divider>
        <div class="text-center" style="font-size: 85%">
          {{ $t("footer.visionsub") }}
        </div>
      </v-col>
      <v-col md="1"> </v-col>
      <v-col cols="12" md="3">
        <div class="text-center">
          <v-btn text plain x-large> {{ $t("footer.rights") }}</v-btn>
        </div>
        <v-divider> </v-divider>
        <div class="text-center pa-5" style="font-size: 85%">
          {{ $t("footer.copy") }}
        </div>
      </v-col>
      <v-col md="1"></v-col>

      <v-col cols="12" md="3">
        <div class="text-center">
          <v-dialog v-model="dialog" scrollable width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                dark
                x-large
                text
                plain
                v-bind="attrs"
                v-on="on"
              >
                {{ $t("footer.contact") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Send us an email</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <form
                  action="https://formsubmit.co/info@hidaarchive.com"
                  method="POST"
                >
                  <input type="hidden" name="_captcha" value="false" />

                  <input
                    type="hidden"
                    name="_next"
                    value="https://hidaarchive.com/"
                  />
                  <label>Name</label>
                  <input
                    type="text"
                    v-model="name"
                    name="name"
                    required
                    placeholder="john legend"
                  />
                  <label>Email</label>
                  <input
                    type="email"
                    v-model="email"
                    name="email"
                    placeholder="example@gmail.com"
                    required
                  />
                  <label>Message</label>
                  <textarea
                    name="message"
                    v-model="message"
                    type="text"
                    cols="30"
                    rows="5"
                    placeholder="message "
                  ></textarea>
                  <input type="submit" value="Send" />
                </form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>

        <v-divider> </v-divider>
        <div class="text-center mx-auto">info@hidaarchive.com</div>
        <v-row class="pa-0 ma-0" justify="center">
          <v-col align="center">
            <v-btn text x-small
              ><a
                href="https://vimeo.com/user167651693"
                style="text-decoration: none; color: black"
                target="_blank"
                ><v-icon>mdi-vimeo</v-icon></a
              >
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn text x-small
              ><a
                href="https://www.instagram.com/"
                style="text-decoration: none; color: black"
                target="_blank"
                ><v-icon>mdi-instagram</v-icon></a
              >
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn text x-small
              ><a
                href="https://www.facebook.com/profile.php?id=100083054964663&ref=pages_you_manage"
                style="text-decoration: none; color: black"
                target="_blank"
                ><v-icon>mdi-facebook</v-icon></a
              >
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn text x-small
              ><a
                href="https://twitter.com/account/access"
                style="text-decoration: none; color: black"
                target="_blank"
                ><v-icon>mdi-twitter</v-icon></a
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  name: "foot-div",
  data() {
    return {
      dialogm1: "",
      dialog: false,
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "YOUR_SERVICE_ID",
          "info@hidaarchive.com",
          e.target,
          "YOUR_USER_ID",
          {
            name: this.name,
            email: this.email,
            message: this.message,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.v-btn {
  font-family: "Amiri", serif;
  font-weight: bolder;
  font-size: 110%;
}
.container {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 20px;
  width: 50%;
}

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
 