<template>
  <div>
    <v-row flat class="lang hidden-sm-and-down" style="color: black">
      <v-btn
        color="#EEEEEE"
        text
        @click="change()"
        height="50px"
        class="ml-8 mr-8"
      >
        <template>
          <span class="v-label-white white--text" v-if="!bool">English</span>
          <span
            class="v-label-white white--text mb-2"
            v-if="bool"
            style="font-family: 'Amiri', serif; font-size: 135%"
            >عربي</span
          >
        </template>
      </v-btn>
    </v-row>
    <!-- mobile view -->
    <v-row
      justify="center"
      flat
      no-gutters
      class="lang hidden-md-and-up mt-0"
      style="height: 50px"
    >
      <v-col cols="3" class="pa-0 ma-0">
        <v-btn text color="#EEEEEE" @click="change()" class="mt-1">
          <template>
            <span v-if="!bool">English</span>
            <span
              class="v-label-white white--text mb-2"
              v-if="bool"
              style="font-family: 'Amiri', serif; font-size: 135%"
              >عربي</span
            >
          </template>
        </v-btn>
      </v-col>
      <v-col cols="5" class="pa-0">
        <div style="color: white">
          <v-text-field
            v-model="search"
            height="30px"
            class="pa-0"
            autofocus
            dark
            style="font-family: 'Amiri', serif"
            v-if="show"
          ></v-text-field>
        </div>
      </v-col>
      <v-col align="center" cols="2" class="pt-1 px-2">
        <v-btn
          @click="
            callsearch();
            show = !show;
          "
          text
          color="white"
        >
          <v-icon>mdi-magnify</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <!-- medium screan view -->
    <div class="hidden-sm-and-down">
      <v-toolbar flat class="tool" style="width: 100%; height: 110px">
        <div class="">
          <v-toolbar-items class="ml-10 mr-10" style="font-size: 90%">
            <v-container fluid>
              <v-row class="justify-end">
                <a href="/">
                  <v-btn text style="font-weight: 1500; font-size: 80%">
                    <h3>{{ $t("navbar.home") }}</h3></v-btn
                  ></a
                >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="font-weight-black"
                      v-bind="attrs"
                      v-on="on"
                      style="font-weight: 1500; font-size: 80%"
                    >
                      {{ $t("navbar.groupes") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <a href="/photogroupes">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown.first") }}
                      </v-list-item>
                    </a>
                    <a href="/video">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown.second") }}
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
                <a href="/certificate/1">
                  <v-btn text style="font-weight: 1500; font-size: 80%"
                    ><h3>{{ $t("navbar.certificate") }}</h3></v-btn
                  >
                </a>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="font-weight-black"
                      v-bind="attrs"
                      v-on="on"
                      style="font-weight: 1500; font-size: 80%"
                    >
                      {{ $t("navbar.studies") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <a href="/studies/GraduationProjects">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown2.first") }}
                      </v-list-item>
                    </a>
                    <a href=" /studies/InstitutePublications">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown2.second") }}
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-row>
            </v-container>
          </v-toolbar-items>
        </div>
        <!-- <v-spacer></v-spacer> -->

        <v-text-field
          class="mt-5"
          v-model="search"
          @change="callsearch()"
          style="color: red"
        ></v-text-field>
        <v-btn @click="callsearch()" text> <v-icon>mdi-magnify</v-icon></v-btn>

        <v-spacer></v-spacer>

        <div>
          <v-img
            :src="require('@/assets/Logo.png')"
            contain
            height="150"
            width="135"
            class="mt-12 ml-16 mr-16"
          ></v-img>
        </div>
      </v-toolbar>

      <v-divider
        style="background-color: #bdbdbd; height: 5px; width: 100%"
      ></v-divider>
    </div>
    <!-- small screan view -->
    <div class="hidden-md-and-up">
      <v-container fluid>
        <v-row class="ma-0 pa-0" justify="center">
          <v-col cols="2" style="padding-right: 0">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon
                  size="50"
                  color="black"
                  text
                  class="font-weight-black"
                  v-on="on"
                >
                  mdi-menu
                </v-icon>
                <br />
                <span
                  style="color: black; font-weight: 600; font-size: 90%"
                  class="px-2 pb-3"
                >
                  {{ $t("list") }}
                </span>
              </template>

              <v-list>
                <a href="/">
                  <v-list-item>
                    {{ $t("navbar.home") }}
                  </v-list-item>
                </a>
                <a href="/photogroupes">
                  <v-list-item>
                    {{ $t("dropdown.first") }}
                  </v-list-item>
                </a>
                <a href="/video">
                  <v-list-item>
                    {{ $t("dropdown.second") }}
                  </v-list-item>
                </a>
                <a href="/certificate/1">
                  <v-list-item>
                    {{ $t("navbar.certificate") }}
                  </v-list-item>
                </a>
                <!-- <v-list-item to="/studies"> -->
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      block
                      v-bind="attrs"
                      v-on="on"
                      style="font-weight: 1500; font-size: 95%"
                    >
                      {{ $t("navbar.studies") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <a href="/studies/GraduationProjects">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown2.first") }}
                      </v-list-item>
                    </a>
                    <a href="/studies/InstitutePublications">
                      <v-list-item style="font-weight: 1500; font-size: 80%">
                        {{ $t("dropdown2.second") }}
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="10">
            <v-img
              :src="require('@/assets/Logo.png')"
              flex
              height="100px"
              width="145px"
              class="mx-auto mr-16"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "navbar-elment",
  data() {
    return {
      word: localStorage.getItem("lang") || "ar",
      bool: false,
      search: "",
      show: false,
      studies: [],
    };
  },
  methods: {
    change() {
      this.$vuetify.rtl = !this.$vuetify.rtl;
      let temp = localStorage.getItem("lang") || "ar";
      if (temp == "en") {
        localStorage.setItem("lang", "ar");
        this.$i18n.locale = "ar";
        window.location.reload();
      }
      if (temp == "ar") {
        localStorage.setItem("lang", "en");
        this.$i18n.locale = "en";
        window.location.reload();
      }
    },
    callsearch() {
      if (this.search == "") {
        return;
      }
      var search1 = " " + this.search + " ";
      this.$router.push({
        path: `/search/${search1}`,
      });
    },
  },
  created() {
    if (this.word == "ar") {
      this.bool = false;
    }
    if (this.word == "en") {
      this.bool = true;
    }
    this.axios.get("/category").then((resp) => {
      this.studies = resp.data.data;
    });
  },
};
</script>
<style >
.lang {
  width: auto;
  margin: 0%;
  background-color: #212121;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.gcse-search {
  border: 5px solid !important;
  border-color: red !important;
  margin-top: 10px !important;
}
.v-text-field {
  padding-top: 0 !important;
  color: white !important;
}
a {
  text-decoration: none;
  color: #212121;
}
</style>
